import { Label, Accordion } from '@auspost/postmaster-react';
import styles from './ProductDetail.module.scss';
import ChangeTrackInput from '../molecules/ChangeTrackInput';
import ChangeTrackSelect from '../molecules/ChangeTrackSelect';
import ChangeTrackCheckbox from '../molecules/ChangeTrackCheckbox';

const ProductDetail = ({ product, setProduct, showChanges, partner }: {product: any, setProduct: any, showChanges: any, partner: any}) => {
    return (
        <>
            {partner?.stockTypes && <div className={`${styles.productDetail}`}>
                <div className='detail'>
                    <div className="flex-container">
                        <div className="flex-cell-2"><Label>Product Name</Label></div>
                        <div className="flex-cell-fill">
                            <ChangeTrackInput required trackChanges={showChanges} entity={product} field="ProductName" setEntity={setProduct} />
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="flex-cell-2"><Label>Product Code</Label></div>
                        <div className="flex-cell-fill">
                            <ChangeTrackInput trackChanges={showChanges} entity={product} field="ProductCode" setEntity={setProduct} />
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="flex-cell-2"><Label>Stock Type</Label></div>
                        <div className="flex-cell-fill">
                            <ChangeTrackSelect options={partner.stockTypes.map((p: any) => { return { label: p.AdapterName, value: p.AdapterID, key: p.AdapterID }})} 
                                trackChanges={showChanges} entity={product} field="AdapterID" setEntity={setProduct} />
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="flex-cell-2"><Label>Stock File Structure</Label></div>
                        <div className="flex-cell-fill">
                            <ChangeTrackSelect options={partner.stockFileStructures.map((p: any) => { return { label: p.SubAdapterName, value: p.SubAdapterID, key: p.SubAdapterID }})} 
                                trackChanges={showChanges} entity={product} field="SubAdapterID" setEntity={setProduct} />
                        </div>
                    </div>
                    <Accordion id="salesAccordion" className='accordion' title="Sales Attributes">
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>% Margin</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="SAPMargin" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Product Fin Code</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="financialCode" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>LPO %</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="LPOMargin" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Tax Treatment</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="SAPTaxTreatment" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>LIPOMS Code</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="LIPOMSCode" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>FACSFAP Code</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="FACSFAPCode" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>RAF Service Code</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="RAFServiceCode" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Charge GL Code</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="ChargeGLCode" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Material Number</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="MaterialNumber" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Agency Number</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput trackChanges={showChanges} entity={product} field="AgencyNumber" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Integ Model</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="IntegModel" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Rout Model</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="RoutModel" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>SAP Work Centre</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="SAPWorkCentre" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Minimum Expiry Days</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="MinDaysToExpiry" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Minimum Value $</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="MinimumValue" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Maximum Value $</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="MaximumValue" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Max Order Qty</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="QuantityBusinessMax" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Default Order Qty</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="QuantityBusinessDefault" setEntity={setProduct} />
                            </div>
                        </div>                        
                    </Accordion>
                    <Accordion id="posFieldsAccordion" className='accordion' title="POS Field Names">
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>PIN Field Name</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="PINFieldName" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Voucher Expiry Field Text</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="ExpiryFieldName" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Voucher Value Field Text</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="ValueFieldName" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Serial Number Field Text</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required trackChanges={showChanges} entity={product} field="ControlCodeFieldName" setEntity={setProduct} />
                            </div>
                        </div>
                    </Accordion>
                    <Accordion id="msgsAccordion" className='accordion' title="Instructions &amp; Messages">                                            
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Reversal Notifications</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackCheckbox trackChanges={showChanges} entity={product} 
                                    field="EmailForReversal" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Reversal Notification Email Address</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required={product.EmailForReversal} disabled={!product.EmailForReversal} trackChanges={showChanges} entity={product} field="ReversalEmailAddress" setEntity={setProduct} />
                            </div>
                        </div>
                        <hr />
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Voucher Instructions</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput required fieldType="textarea" trackChanges={showChanges} entity={product} field="VoucherInstructions" setEntity={setProduct} />                                
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Promotional Message</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput fieldType="textarea" trackChanges={showChanges} entity={product} field="PromMessage" setEntity={setProduct} />
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-cell-2"><Label>Sales Message</Label></div>
                            <div className="flex-cell-fill">
                                <ChangeTrackInput fieldType="textarea" trackChanges={showChanges} entity={product} field="SalesMessage" setEntity={setProduct} />
                            </div>
                        </div>
                    </Accordion>
                </div>
            </div>}
        </>
    );
}

export default ProductDetail;

export interface IProduct {
    ProductID: number, PartnerID: number,
    ProductName: string | null, ProductCode: string,
    //AUS40678-new product issue
    // numSAPMargin: number,
    SAPMargin: String | null,
    financialCode: string, VoucherInstructions: string, PromMessage: string, 
    SalesMessage: "", QuantityBusinessDefault: number, QuantityBusinessMax: number, MaximumValue: number | null, MinimumValue: number | null, 
    MinDaysToExpiry:number | null,
    //AUS40678-new product issue
    // SAPWorkcentre: string,
    SAPWorkCentre: string,
    RoutModel: string, IntegModel: string, AgencyNumber: number, MaterialNumber: string,
    ChargeGLCode: string, RAFServiceCode: string, FACSFAPCode: string, LIPOMSCode: string, SAPTaxTreatment: string,
    //AUS40678-new product issue
    LPOMargin: string | null,
    PINFieldName: string,
    ExpiryFieldName: string, ValueFieldName: string, ControlCodeFieldName: string, ReversalEmailAddress: string | null, ProductStatus: string,
    AdapterID:1,SubAdapterID:2,EmailForReversal: boolean, StatusID: number,
    // Not mapped to UI?
    SAPProductCode: "", PassPhrase: "",PassPhraseKeyID:"-1",Barcode: "", Separator: ""
}
export const emptyProduct: IProduct = { ProductID: -1, PartnerID: -1,
    ProductName: null, ProductCode: "",
    //AUS40678-new product issue
    // numSAPMargin: 0,
    SAPMargin: null,
    financialCode: "", VoucherInstructions: "", PromMessage: "", 
    SalesMessage: "", QuantityBusinessDefault: 1, QuantityBusinessMax: 3, MaximumValue: null, MinimumValue: null, 
    MinDaysToExpiry: null,
    //AUS40678-new product issue
    // SAPWorkcentre: " ",
    SAPWorkCentre: "",
    RoutModel: "70", IntegModel: "1", AgencyNumber:7, MaterialNumber: "",
    ChargeGLCode: "", RAFServiceCode: "", FACSFAPCode: "", LIPOMSCode: "", SAPTaxTreatment: "ZZ",
    //AUS40678-new product issue
    LPOMargin: null,
    PINFieldName: "",
    ExpiryFieldName: "", ValueFieldName: "", ControlCodeFieldName: "", ReversalEmailAddress: null, ProductStatus: "Loading...",
    AdapterID:1,SubAdapterID:2,EmailForReversal: false, StatusID: -1,
    // TODO: if we need this in db it should be populated in lambda
    // Not mapped to UI?
    SAPProductCode: "", PassPhrase: "",PassPhraseKeyID:"-1",Barcode: "", Separator: ""
};
export const isValidProduct = (product: IProduct, setInvalidFields: React.Dispatch<React.SetStateAction<string[]>>) => {
    let invalidFields: string[] = [];
    if(!product.ProductName)
        invalidFields.push("Product Name");
    if(!product.LIPOMSCode)
        invalidFields.push("LIPOMS Code");
    //AUS40678-new product issue
    // if(!product.LPOMargin && product.LPOMargin !== 0)
    if(!product.LPOMargin )
        invalidFields.push("LPO %");
    //AUS40678-new product issue
    if(!product.SAPMargin)
        invalidFields.push("% Margin");
    if(!product.SAPTaxTreatment)
        invalidFields.push("Tax Treatment");  
    if(!product.FACSFAPCode)
        invalidFields.push("FACSFAP Code");
    if(!product.RAFServiceCode)
        invalidFields.push("RAF Service Code");    
    if(!product.ChargeGLCode)
        invalidFields.push("Charge GL Code");    
    if(!product.MaterialNumber)
        invalidFields.push("Material Number");
    if(!product.IntegModel)
        invalidFields.push("Integ Model");    
    if(!product.RoutModel)
        invalidFields.push("Rout Model");
    //AUS40678-new product issue
    // if(!product.SAPWorkcentre)
    if(!product.SAPWorkCentre)
        invalidFields.push("SAP Work Centre");
    if(!product.MinDaysToExpiry && product.MinDaysToExpiry !== 0)
        invalidFields.push("Minimum Expiry Days");
    if(!product.MinimumValue && product.MinimumValue !== 0)
        invalidFields.push("Minimum Value");
    if(!product.MaximumValue && product.MaximumValue !== 0)
        invalidFields.push("Maximum Value");
    if(!product.QuantityBusinessMax && product.QuantityBusinessMax !== 0)
        invalidFields.push("Max Order Qty");
    if(!product.QuantityBusinessDefault && product.QuantityBusinessDefault !== 0)
        invalidFields.push("Default Order Qty");
    if(!product.PINFieldName)
        invalidFields.push("PIN Field Name");
    if(!product.ExpiryFieldName)
        invalidFields.push("Voucher Expiry Field Text");
    if(!product.ValueFieldName)
        invalidFields.push("Voucher Value Field Text");
    if(!product.ControlCodeFieldName)
        invalidFields.push("Serial Number Field Text");
    if(product.EmailForReversal && (!product.ReversalEmailAddress || product.ReversalEmailAddress.length <= 0))
        invalidFields.push("Reversal Email");
    if(!product.VoucherInstructions)
        invalidFields.push("Voucher Instructions");
    setInvalidFields(invalidFields);
    return invalidFields.length <= 0;
}